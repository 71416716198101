//===================================================================================
// External
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Interna
import { formateDate, rowColor, timeDiffInDays } from '../../utils/utils';
import { useGetAgents } from '../../hooks/commonHooks';
import { Tooltip } from 'react-tooltip';
//===================================================================================

const QueryCard = ({ query, number }) => {
  const navigate = useNavigate();
  const {
    _id: queryId,
    agentId,
    dateSentToAgent,
    dateSentToInbox,
    status,
    createdAt,
    updatedAt,
  } = query;
  const agent = useGetAgents()[String(agentId)];

  const {
    firstName,
    lastName,
    agency,
    queryLetterRequired,
    synopsisRequired,
    pageCount,
    pagesType
  } = agent;

  const handleDateStatus = () => {
    if ((!status || status === 'waiting') && dateSentToAgent) {
      return `Sent to Agent ${timeDiffInDays(dateSentToInbox)} Day(s) Ago`
    }

    if ((!status || status === 'waiting') && dateSentToInbox) {
      return `Sent to 'Drafts' ${timeDiffInDays(dateSentToInbox)} Day(s) Ago`
    }

    if (status === 'manuscript-request-full') return 'Manuscript Request - Full';
    if (status === 'manuscript-request-partial') return 'Manuscript Request - Partial';
    if (status === 'revise-resubmit') return 'Revise & Resubmit';
    if (status === 'rejection-form') return 'Form Rejection (Closed)';
    if (status === 'rejection-personal') return 'Personal Rejection (Closed)';
    if (status === 'representation-offer') return 'Representation Offer (Closed)';
    if (status === 'no-response') return 'No Response (Closed)';
    return `Started`
  }

  const handleNavigate = () => {
    if (!query.queryFormat || query.queryFormat === 'Email') {
      if (!query.dateSentToInbox) {
        navigate(`/query/${queryId}`, { state: { queryId } })
        return;
      }

      if (query.dateSentToAgent) {
        navigate(`/query-thread/${queryId}`) //nav to new page
        return;
      }

      if (query.dateSentToInbox) {
        navigate(`/query-final-review/${queryId}`) //nav to new page
        return;
      }
    }

    if (query.queryFormat === 'QueryManager') {
      if (query.queryManagerPersonalLink) {
        navigate(`/query-manager-review/${queryId}`)
        return;
      } else {
        navigate(`/query-manager-submit/${queryId}`)
        return;
      }
    }

    if (query.queryFormat === 'CustomForm') {
      navigate(`/agent-custom-form-review/${queryId}`)
      return;
    }
  }

  const handleRequirements = () => {
    const requirements = []
    if (queryLetterRequired) requirements.push('Q');
    if (synopsisRequired) requirements.push('S');
    if (pagesType === 'Words') requirements.push('W')
    if (pagesType === 'Pages') requirements.push('P')
    if (pagesType === 'Chapter') requirements.push('C')
    return requirements;
  }

  const renderRequirements = () => {
    const styles = {
      fontSize: '14px',
      maxWidth: '30%',
      whiteSpace: 'pre-wrap'
    }

    const tooltipText = {
      'S': `Synopsis`,
      'Q': `Query Letter`,
      'P': `${pageCount} Pages`,
      'W': `${pageCount} Words`,
      'C': `${pageCount} Chapter(s)`
    };

    const requirements = handleRequirements()
    return requirements.map(req => {
      return (
        <span
          data-tooltip-id="my-tooltip"
          data-tooltip-content={`${tooltipText[req]}`}
        >
          <Tooltip id="my-tooltip" style={styles} multiline />
          <span id='Requirement'>{req}</span>
        </span>
      )
    })
  }

  return (
    <tr
      className={`QueryCard__container`}
      onClick={() => handleNavigate()}
      style={{ backgroundColor: rowColor(number) }}
    >
      <td>{number}</td>
      <td className='QueryCard__name'>
        {firstName} {lastName}
      </td>
      <td className='QueryCard__agency'>
        {agency}
      </td>
      <td className='QueryCard__status'>
        {handleDateStatus()}
      </td>
      <td className='QueryCard__requirements'>
        {renderRequirements()}
      </td>
      <td>{updatedAt ? formateDate(updatedAt) : formateDate(createdAt)}</td>
    </tr>
  )
}

export default QueryCard;
