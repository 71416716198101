//==============================================================================
// External:
import React from 'react';
import deepEqual from 'deep-equal';

// Internal:
import CheckListItem from '../CheckListItem/CheckListItem';
import SelectAgent from './Subcomponents/SelectAgent/SelectAgent';
import EmailSubject from './Subcomponents/EmailSubject/EmailSubject';
import QueryLetterEditor from './Subcomponents/QueryLetterEditor/QueryLetterEditor';
import useQueryBuilderFormHooks from './QueryBuilderForm.hooks';
import { hasExistingGmailDraft, objHasData } from '../../utils/utils';
import {
  deleteQuery,
  finalizeQuery,
  gmailSendDraft,
  updateQuery
} from '../../store/actions/actions';
//==============================================================================

// see Note 1 for preselectedQuery & preselectedAgentId info
const QueryBuilderForm = ({ preselectedQuery = {}, preselectedAgentId = '' }) => {
  const {
    agent,
    agentId,
    agents,
    dispatch,
    emailSubjectFS,
    existingQuerySubstitutions,
    existingSubstitutionEntriesFS,
    finalEmail,
    finalEmailHTML,
    navigate,
    newQueryLetterText,
    newSubstitutionEntriesFS,
    projectId,
    queryFormat,
    queryTemplate,
    setAgentId,
    setEmailSubjectFS,
    setExistingSubstitutionEntriesFS,
    setNewQueryLetterText,
    setNewSubstitutionEntriesFS,
    substitutionTemplates,
  } = useQueryBuilderFormHooks(preselectedQuery, preselectedAgentId)

  const selectAgentProps = {
    agent,
    agentId,
    agents,
    preselectedQuery,
    setAgentId,
  }

  const queryLetterEditorProps = {
    agentId,
    emailSubjectFS,
    existingQuerySubstitutions,
    existingSubstitutionEntriesFS,
    finalEmailHTML,
    newQueryLetterText,
    newSubstitutionEntriesFS,
    setExistingSubstitutionEntriesFS,
    setNewQueryLetterText,
    setNewSubstitutionEntriesFS,
    substitutionTemplates,
  }

  const queryIsComplete = () => {
    if (!objHasData(agent)) return false;
    if (!emailSubjectFS) return false;
    if (!finalEmailHTML) return false;
    if (!allSubsHaveFinalText({ ...existingSubstitutionEntriesFS })) return false;
    return true;
  }

  const allSubsHaveFinalText = (substitutionEntries) => {
    return Object.values(substitutionEntries).every(subEntry => {
      return Boolean(subEntry.finalText);
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const hasUpdatedSubstritutionEntries =
      !deepEqual(existingSubstitutionEntriesFS, existingQuerySubstitutions)
      || Object.keys(newSubstitutionEntriesFS).length > 0;

    const props = {
      finalEmailText: finalEmail,
      emailSubject: emailSubjectFS,
      queryLetterText: newQueryLetterText,
      agentId,
      queryFormat,
      queryTemplateId: preselectedQuery?.queryTemplateId || queryTemplate._id,
      projectId: projectId || preselectedQuery.projectId,
      navigate,
      substitutionEntries: hasUpdatedSubstritutionEntries ? { existingSubstitutionEntriesFS, newSubstitutionEntriesFS } : {},
    };

    if (objHasData(preselectedQuery)) {
      dispatch(updateQuery({ ...props, queryId: preselectedQuery?._id }));
    } else {
      dispatch(finalizeQuery({ ...props }));
    }
  }

  const onClear = () => {
    navigate(`/project/${projectId}/queries`);
  }

  const onDelete = () => {
    dispatch(deleteQuery({ ...preselectedQuery, navigate }));
  }

  const onSendGmailDraft = () => {
    dispatch(
      gmailSendDraft({
        agent,
        query: preselectedQuery,
        email: finalEmail,
        projectId: preselectedQuery.projectId,
        navigate
      })
    )
  }

  const sendToGmailButton = () => {
    if (!preselectedQuery._id) return;
    if (hasExistingGmailDraft(preselectedQuery)) return;
    if (!queryFormat) return;

    const button = queryFormat === 'Email' ?
      <button
        className='QueryBuilderForm__sendToGmail'
        disabled={!queryIsComplete()}
        onClick={() => onSendGmailDraft()}
        type='button'
      >
        Send Draft To Gmail
      </button>
      : <button
        className='QueryBuilderForm__sendToGmail'
        disabled={!queryIsComplete()}
        onClick={() => navigate(`/query-manager-submit/${preselectedQuery._id}`)}
        type='button'
      >
        Post To QueryManager
      </button>

    return (
      button
    )
  }

  return (
    <div className='QueryBuilderForm__container'>
      <form className='QueryBuilderForm__formContainer' onSubmit={(event) => handleSubmit(event)}>
        <div className='QueryBuilderForm__checklistContainer'>
          <CheckListItem
            component={<SelectAgent {...selectAgentProps} />}
            hasCompletedStep={Boolean(agentId)}
            number='1'
            openByDeault
            title="Select Agent To Query"
          />
          <CheckListItem
            component={<EmailSubject emailSubjectFS={emailSubjectFS} setEmailSubjectFS={setEmailSubjectFS} />}
            isDefaultColor={true}
            number='2'
            openByDeault
            title="Set Email Subject"
          />
          <CheckListItem
            component={<QueryLetterEditor {...queryLetterEditorProps} />}
            isDefaultColor={true}
            isLastItem
            number='3'
            title="Complete Query Letter"
          />
        </div>
        <div className='QueryBuilderForm__buttonContainer'>
          <button disabled={!agentId && !preselectedQuery._id} >
            Save Query
          </button>
          {
            sendToGmailButton()
          }
          <button
            type='button'
            onClick={() => preselectedQuery._id ? onDelete() : onClear()}
            className='QueryBuilderForm__deleteButton'
          >
            {preselectedQuery._id ? 'Delete Query' : 'Discard Query Changes'}
          </button>
        </div>
      </form>
    </div >
  )
}

export default QueryBuilderForm;

/*
NOTES:
1. 'preselectedQuery' is when clicking on main query from QueryBuilder page list. The 'preselectedAgent'
   is when clicking 'Query This Agent' on the agent page
*/

