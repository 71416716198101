//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';

// Internal:
import AgentRating from '../AgentRating/AgentRating';
import { useAgentCardHooks } from './AgentCard.hooks';
import {
  hasBeenQueried,
  onClickHandler,
  setIsOpenClass,
  setIsOpenIcon
} from './AgentCard.utils';
import { rowColor, tooltipStyles } from '../../utils/utils';
//==============================================================================

const AgentCard = ({
  agent,
  filter,
  parentProps,
  shouldAllowDnD
}) => {

  const {
    navigate,
    projectId,
    queriesByAgentArray,
    query,
  } = useAgentCardHooks(agent);

  const {
    _id: agentId,
    agency,
    firstName,
    isOpen,
    lastName,
    priority,
    queryFormat,
    rating,
    tags,
  } = agent;

  const {
    isDragged,
    isSelected,
    key,
    ref,
    style,
  } = parentProps;

  const displayPriority = () => {
    return filter === 'agentsToQuery' ? priority : '--'
  }

  const commonTDStyles = (shouldAddWidth = false) => ({
    borderBottom: parentProps.isDragged && 'none',
    borderTop: parentProps.isDragged && 'none',
    ...(shouldAddWidth && { width: '10px' })
  });

  const handleCursor = (isDragged) => {
    if (!shouldAllowDnD) return 'auto';
    return isDragged ? "grabbing" : "grab"
  }

  const handleBackgroundColor = (isDragged, isSelected, key) => {
    if (isDragged) return "#dcdcdc";
    return rowColor(key);
  }

  // see Note1
  const draggingStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px dashed black',
    cursor: handleCursor(parentProps.isDragged),
    opacity: 0.5,
  }

  const formatTags = () => {
    if (tags.length === 0) return '--';
    return tags.map(tag => {
      return (
        <div className='AgentCard__tagsItem'>
          {tag}
        </div>
      )
    })
  }

  const formatQueryType = () => {
    if (!queryFormat) return '--';
    if (queryFormat === 'Email') {
      return (
        <>
          <FontAwesomeIcon
            className='AgentCard__envelopeIcon'
            icon='fa-envelope-open'
            data-tooltip-id="my-tooltip"
            data-tooltip-content={`Email`}
          />
          <Tooltip id="my-tooltip" style={tooltipStyles} multiline />
        </>
      )
    }
    if (queryFormat === 'QueryManager') {
      return (
        <>
          <div
            data-tooltip-id="my-tooltip"
            data-tooltip-content={`Query Manager`}
          >
            Qm
            <Tooltip id="my-tooltip" style={tooltipStyles} multiline />
          </div>
        </>
      )
    }
    if (queryFormat === 'CustomForm') {
      return (
        <>
          <FontAwesomeIcon
            className='AgentCard__envelopeIcon'
            icon='fa-list-ol'
            data-tooltip-id="my-tooltip"
            data-tooltip-content={`Custom Form`}
          />
          <Tooltip id="my-tooltip" style={tooltipStyles} multiline />
        </>
      )
    }
  }

  return (
    <tr
      ref={ref}
      {...parentProps}
      key={key}
      style={{
        ...style,
        ...(isDragged && { ...draggingStyles }),
        backgroundColor: handleBackgroundColor(isDragged, isSelected, key),
      }}
      className={`AgentCard__container`}
    >
      <td className="AgentCard__gripDotsContainer" style={commonTDStyles()}>
        {shouldAllowDnD && <span><FontAwesomeIcon icon="fa-grip-vertical" className="AgentCard__gripDots" /></span>}
      </td>
      <td style={commonTDStyles(true)}>
        {displayPriority()}
      </td>
      <td style={commonTDStyles()}>
        <a
          onClick={() => navigate(`/project/${projectId}/agent-edit/${agentId}`)}
          className="AgentCard__link"
        >
          {`${firstName} ${lastName}`}
        </a>
      </td>
      <td style={commonTDStyles()}>
        {agency}
      </td>
      <td className='AgentCard__rating' style={commonTDStyles()}>
        {<AgentRating rating={rating} />}
      </td>
      <td className='AgentCard__tagsContainer'>
        {formatTags()}
      </td>
      <td className='AgentCard__queryFormatContainer'>
        {formatQueryType()}
      </td>
      <td className={`AgentCard__editIconContainer ${setIsOpenClass(isOpen)}`} style={commonTDStyles()}>
        {setIsOpenIcon(isOpen)}
      </td>
      <td className='AgentCard__queryIconContainer' style={commonTDStyles()}>
        <button onClick={() => onClickHandler(agent, navigate, projectId, query)} >
          <FontAwesomeIcon
            className={`AgentCard__envelopeIcon ${hasBeenQueried(queriesByAgentArray, agent) && 'grey'}`}
            icon="fa-pen-nib"
          />
        </button>
      </td>
      <td style={commonTDStyles()}>
        <a
          className='AgentCard__editIconContainer'
          onClick={() => navigate(`/project/${projectId}/agent-edit/${agentId}`)}
        >
          {<FontAwesomeIcon icon="fa-pen" className="AgentCard__editIcon" />}
        </a>
      </td>
    </tr>
  )
}

export default AgentCard;

/*
NOTES:
1. The item being dragged loses its CSS stylings for some reason (maybe bc item is take out of the doc flow w 'position: fixed'?)
   and would scrunch up. The fix was to the use inline JS stylings.
*/
