//===================================================================================
// External
import React from 'react';

// Internal
import AgentList from '../../components/AgentList/AgentList';
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import ButtonCreateQuery from '../../components/ButtonCreateQuery/ButtonCreateQuery';
import { useAgentsHooks } from './Agents.hooks';
//===================================================================================

const Agents = () => {
  const {
    agents,
    dispatchSetPriority,
    filter,
    projectId,
    projectTitle,
    searchCriteria,
    searchTerm,
    setAgents,
    setFilter,
    setSearchCriteria,
    setSearchTerm,
    setSort,
    shouldAllowDnD,
    sort,
  } = useAgentsHooks();

  const onDrop = (newOnDropList) => {
    setAgents(newOnDropList);
    dispatchSetPriority({ newOnDropList })
  }

  return (
    <div className="Agents__container">
      <PageTitleBar
        title={projectTitle}
        subTitle={'Agents'}
      />
      <div className='Agents__subPageContainer'>
        <div className='Agents__buttonsContainer'>
          <ButtonCreateQuery
            route={`/project/${projectId}/agent-new`}
            text='Add New Agent'
          />
          <div className='Agents__searchContainer'>
            <label>Search By:</label>
            <input
              className='Agents__searchInput'
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}
            />
          </div>
          <div className='QueryBuilder__dropdownContainer'>
            <label>Filter By:</label>
            <select value={filter} onChange={(event) => setFilter(event.target.value)}>
              <option value="agentsToQuery">Agents On Query List</option>
              <option value="doNotQuery">Agents On Do Not Query List</option>
              <option value="openToQueries">Open To Queries</option>
              <option value="closedToQueries">Closed To Queries</option>
              <option value="allAgents">All Agents</option>
            </select>
          </div>
          <div className='QueryBuilder__dropdownContainer'>
            <label>Sort By:</label>
            <select value={sort} onChange={(event) => setSort(event.target.value)}>
              <option value="priority">Priority</option>
              <option value="alphabetical">Alphabetical By Last Name</option>
              <option value="alphabetical-agency">Alphabetical By Agency</option>
              <option value="rating">Rating</option>
            </select>
          </div>
        </div>
        <AgentList
          agentsList={agents}
          filter={filter}
          onDrop={onDrop}
          shouldAllowDnD={shouldAllowDnD}
        />
      </div>
    </div>
  )
}

export default Agents;
