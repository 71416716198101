//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Internal:
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import CheckListItem from '../../components/CheckListItem/CheckListItem';
import SubmitToQueryManager from './Subcomponents/SubmitToQueryManager/SubmitToQueryManager';
import QueryManagerPersonalLink from './Subcomponents/QueryManagerPersonalLink/QueryManagerPersonalLink';
import {
  useGetAgents,
  useGetQueries,
  useGetQueryTemplate,
  useGetUser
} from '../../hooks/commonHooks';
import { deleteQuery } from '../../store/actions/actions';
//==============================================================================

const QueryManagerSubmit = () => {
  const { queryId } = useParams();
  const user = useGetUser();
  const queryTemplate = useGetQueryTemplate()
  const query = useGetQueries()[queryId];
  const agent = useGetAgents()[query.agentId];
  const [queryManagerPersonalLink, setQueryManagerPersonalLink] = useState(query.queryManagerPersonalLink);
  const [dateSentToAgent, setDateSentToAgent] = useState(query.dateSentToAgent);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const submitToQueryManagerProps = {
    agent,
    query,
    queryTemplate,
    user
  }

  const queryManagerPersonalLinkProps = {
    dateSentToAgent,
    dispatch,
    navigate,
    queryId,
    query,
    queryManagerPersonalLink,
    setDateSentToAgent,
    setQueryManagerPersonalLink,
  }

  const onDeleteEntireQuery = () => {
    dispatch(deleteQuery({ ...query, navigate }));
  }

  useEffect(() => {
  }, [dateSentToAgent])

  return (
    <div className='QueryManagerSubmit__container'>
      <PageTitleBar
        title={'QueryManager'}
        subTitle={'Submit To QueryManager'}
      />
      <div className='QueryManagerSubmit__innerContainer'>
        <div className='QueryManagerSubmit__checklistContainer'>
        <div className='QueryManagerSubmit__headerContainer'>
          <div className='QueryManagerSubmit__agentInfoHeader'>Submitting Query For:</div>
          <div className='QueryManagerSubmit__agentInfoItem'>
            <div className='QueryManagerSubmit__agentInfoLabel'>Agent Name:</div>
            <div>{agent.firstName} {agent.lastName}</div>
          </div>
          <div className='QueryManagerSubmit__agentInfoItem'>
            <div className='QueryManagerSubmit__agentInfoLabel'>Agency:</div>
            <div>{agent.agency}</div>
          </div>
        </div>
          <CheckListItem
            component={<SubmitToQueryManager {...submitToQueryManagerProps} />}
            number='1'
            openByDeault
            title="Submit To QueryManager"
          />
          <CheckListItem
            component={<QueryManagerPersonalLink {...queryManagerPersonalLinkProps} />}
            isLastItem
            number='2'
            title="Enter QueryManager Personal Link"
          />
        </div>
        <div className='QueryManagerSubmit__buttonContainer'>
          <button
            className='QueryManagerSubmit__deleteButton red'
            onClick={() => onDeleteEntireQuery()}
            type="button"
          >
            Delete Query
          </button>
        </div>
      </div>
    </div>
  )
}

export default QueryManagerSubmit;
